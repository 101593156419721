<template>
  <Loading :msg="'Activation de votre en compte, veuillez patienter...'" />
</template>

<script>

import UserService from "@/services/userService";
import Loading from "../../components/loading/Loading";

export default {
  name : "Activation",
  components: {
    Loading
  },
  //call api to activate user after page loaded
  mounted() {
    UserService.activateUser(this.$route.query.activation_token);
  }
};
</script>
<style scoped>
</style>
